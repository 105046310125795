import { createApi } from '@reduxjs/toolkit/query/react';

import { TokensData } from 'src/utilities/auth';
import { axiosBaseQuery } from 'src/utilities/baseQuery';

export type LoginParams = { password: string; user: string };

export const loginApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    login: build.mutation<TokensData, LoginParams>({
      query(data) {
        return {
          data,
          method: 'POST',
          url: '/login',
        };
      },
    }),
  }),
  reducerPath: 'loginApi',
});

export const { useLoginMutation } = loginApi;
